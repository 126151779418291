@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    scroll-behavior: smooth;

    @apply h-full bg-white text-gray-900;
  }

  body {
    @apply h-full overflow-hidden;
  }

  #__next {
    @apply h-full;
  }

  a,
  button {
    @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-opacity-75;
  }
}

@layer components {
  /* Button Styles */
  .btn {
    @apply py-2 px-4 bg-primary-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-primary-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-opacity-75;
    @apply disabled:opacity-70;
  }

  .btn-secondary {
    @apply btn;
    @apply bg-gray-200 text-gray-600 hover:bg-gray-300 hover:text-gray-800 focus-visible:ring-gray-400;
  }

  .btn-outline {
    @apply btn;
    @apply bg-white border border-gray-300 text-gray-700 hover:bg-gray-50;
  }

  .focus {
    @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-opacity-75;
  }

  /* Layout */
  .wrapper {
    @apply px-2 mx-auto md:px-4 py-2 sm:py-4;
  }

  .app-wrapper {
    @apply px-4 mt-4 sm:px-6 sm:mt-6 lg:px-8 max-w-7xl w-full mx-auto;
  }

  /* Forms */
  .label {
    @apply block text-sm font-medium text-gray-700;
  }
  .input {
    @apply disabled:opacity-50;
    @apply shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md;
    @apply placeholder-gray-500;
  }

  /* Typography */
  .subheading {
    @apply text-xl md:text-2xl text-gray-900 font-medium;
  }
  .title {
    @apply text-lg md:text-xl text-gray-900 font-medium;
  }

  .card {
    @apply shadow-md rounded-md bg-white;
  }
}
